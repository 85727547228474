import React from "react";
import { Header } from "../components/headerAndFooter/Header";
import { Footer } from "../components/headerAndFooter/Footer";
import { PawsLangStrings, useTranslate } from "../components/langSwitcher/LangSwitcher";
import { PawsFacebook, PawsIfYouHaveQuestions, PawsInstagram } from "../components/prefabs";
import Collapsible from "react-collapsible";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FeLanguage } from "../fe-const";
import { ProcessedReport2022_2023, SentReport2022_2023 } from "../components/Achievements2023";

const strings: PawsLangStrings = {
  [FeLanguage.Swe]: {
    "Activity reports": "Verksamhetsrapporter",
    "text.reports": "Vi publicerar regelbundet verksamhetsrapporter i våra sociala medier",
    "text.processed-2022-2023": "Behandlade hjälpbegäranden 2022-2023",
    "text.sent-2022-2023": "Skickade förnödenheter 2022-2023",
    Awards: "Utmärkelser",
    "Media Mentions": "Mediaomtal",
    "Events and Demonstrations": "Evenemang och Demonstrationer",
    "Public Actions": "Offentliga Protester",
    Accreditations: "Ackrediteringar",
    Statistics: "Statistik",
    "text.Total requests for help in 2022-2023": "Antal hjälpbegäranden 2022-2023",
    "link.kattliv2-2024": "Artikel publicerad i numret 2/2024 av Kattliv.",
    "text.omKattliv":
      "Kattliv — Sveriges största kattmagasin! " +
      "Spinnande kattläsning, vackra kattbilder, veterinärråd samt beteenderåd, " +
      "pryltester, kattreportage, tävlingar, intervjuer med kända och okända " +
      "kattägare, raspresentationer och mycket mer. Kattliv - För alla oss som " +
      "älskar katter!",
    "link.pdf": "Ladda ner som PDF-fil",
    "report.september": "Månadsrapport — September",
    "report.october": "Månadsrapport — Oktober",
    "title.year": "År",
  },
  [FeLanguage.Ukr]: {
    "Activity reports": "Звіти про діяльність",
    "text.reports": "Ми публікуємо регулярні звіти про нашу діяльність",
    "text.processed-2022-2023": "Оброблені запити на допомогу в 2022-2023 роках",
    "text.sent-2022-2023": "Відправлені посилки в 2022-2023 роках",
    Awards: "Нагороди",
    "Media Mentions": "Згадки в ЗМІ",
    "Events and Demonstrations": "Події та демонстрації",
    "Public Actions": "Громадські акції протесту",
    Accreditations: "Акредитації",
    Statistics: "Статистика",
    "text.Total requests for help in 2022-2023": "Всього запитів на допомогу в 2022-2023 роках",
    "link.kattliv2-2024": "Стаття опублікована в номері 2/2024 журналу Kattliv.",
    "text.omKattliv":
      "Kattliv — найбільший в Швеції журнал про кішок! " +
      "Цікаві статті, чудові фотографії, ветеринарні та поведінкові поради, " +
      "тестування товарів, репортажі про кішок, конкурси, інтерв'ю з відомими та невідомими " +
      "власниками кішок, описи порід та багато іншого. Kattliv - для всіх нас, хто любить кішок!",
    "link.pdf": "Завантажити у форматі PDF",
    "report.september": "Щомісячний звіт — вересень",
    "report.october": "Щомісячний звіт — жовтень",
    "title.year": "Рік",
  },
  [FeLanguage.Eng]: {
    "text.reports": "We regularly publish activity reports in our social media accounts",
    "text.processed-2022-2023": "Processed requests for help in 2022-2023",
    "text.sent-2022-2023": "Sent supplies for help in 2022-2023",
    "text.Total requests for help in 2022-2023": "Total requests for help in 2022-2023",
    "link.kattliv2-2024": "Article published in issue 2/2024 of Kattliv.",
    "text.omKattliv":
      "Kattliv — Sweden's largest cat magazine! " +
      "Purring cat reading, beautiful cat pictures, veterinary advice and behavior advice, " +
      "product tests, cat reports, competitions, interviews with famous and unknown " +
      "cat owners, breed presentations and much more. Kattliv - For all of us who love cats!",
    "link.pdf": "Download as PDF file",
    "report.september": "Monthly — September",
    "report.october": "Monthly — October",
    "title.year": "Year",
  },
};

const AchievementsPage = () => {
  const { translate: t } = useTranslate(strings);

  return (
    <>
      {/*<PawsFacebookScript />*/}
      <Header currentPageId="achievements" />
      <h3>{t("Activity reports")}</h3>
      <p>
        {t("text.reports")}: <PawsFacebook />, <PawsInstagram />
      </p>
      <h3>{t("Statistics")}</h3>
      <Row className={"my-3"}>
        <h4>{t("title.year")} 2024</h4>
        <a href={"/reports/2024/monthly-october-2024.pdf"} target="_blank">
          {t("report.october")} 2024
        </a>
        <a href={"/reports/2024/monthly-september-2024.pdf"} target="_blank">
          {t("report.september")} 2024
        </a>
        <h4>{t("title.year")} 2022-2023</h4>
        <p>
          {t("text.Total requests for help in 2022-2023")}: <strong>525</strong>
        </p>
        <Col sm={12} lg={6}>
          <Collapsible
            trigger={t("text.processed-2022-2023")}
            className="btn btn-secondary"
            transitionCloseTime={1}
            transitionTime={1}
          >
            <ProcessedReport2022_2023 />
          </Collapsible>
        </Col>
        <Col sm={12} lg={6}>
          <Collapsible
            trigger={t("text.sent-2022-2023")}
            className="btn btn-secondary"
            transitionCloseTime={1}
            transitionTime={1}
          >
            <SentReport2022_2023 />
          </Collapsible>
        </Col>
      </Row>
      <h3>{t("Awards")}</h3>
      <h3>{t("Media Mentions")}</h3>
      <p>
        <Link to="https://readly.com/products/magazine/kattliv">{t("link.kattliv2-2024")}</Link>
      </p>
      <p>{t("text.omKattliv")}</p>
      <p>
        <a href="/publications/kattliv-202402.pdf" target="_blank">
          {t("link.pdf")}
        </a>
      </p>
      <h3>{t("Events and Demonstrations")}</h3>
      {/*<h3>{t("Public Actions")}</h3>*/}
      <h3>{t("Accreditations")}</h3>
      <p>
        <PawsIfYouHaveQuestions />
      </p>
      <Footer />
    </>
  );
};

export { AchievementsPage };
