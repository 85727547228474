import React, { FC, ReactNode, useEffect, useMemo } from "react";
import styles from "./LangSwitcher.module.css";
import { useCookies } from "react-cookie";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { FeLanguage, FeResource } from "../../fe-const";
import { Col, Row } from "react-bootstrap";

export type PawsTranslateFn = (key: string) => string;

const getBrowserLanguages = () => {
  const languages = navigator.languages || [];
  return languages.map((lang) => lang.split("-")[0]);
};

interface LangSwitcherSetProps {
  lang: FeLanguage;
}

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export type PawsLangStrings = {
  [lang in FeLanguage]: { [key: string]: ReactNode };
};

export const useTranslate = (strings: PawsLangStrings) => {
  const browserLanguages = getBrowserLanguages();
  const guessFn = () => {
    if (browserLanguages.includes("uk") || browserLanguages.includes("ru")) return FeLanguage.Ukr;
    if (browserLanguages.includes("sv")) return FeLanguage.Swe;
    return FeLanguage.Eng;
  };
  const guessedLanguage = guessFn();

  const [cookies] = useCookies(["lang"]);
  const lang = (cookies.lang as FeLanguage) || guessedLanguage;

  const translate = (key: string): string => {
    const candidate = strings[lang][key] ?? key;
    return candidate.toString();
  };

  const translateJsx = (key: string): ReactNode => {
    return strings[lang][key] ?? key;
  };

  return { lang, translate, translateJsx };
};

export const LangSwitcherPage: FC<LangSwitcherSetProps> = ({ lang }) => {
  const [cookies, setCookie] = useCookies(["lang"]);
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.lang !== lang) {
      setCookie("lang", lang, { path: "/" });
    }
    navigate(query.get("referrer") || FeResource.Root);
  }, [cookies, navigate, lang]);
  return <></>;
};

export const getActiveLanguage = (lang: FeLanguage | undefined) => {
  return lang || FeLanguage.Eng;
};

export const LangSwitcher = () => {
  const [cookies] = useCookies(["lang"]);
  const location = useLocation();

  let activeLang = getActiveLanguage(cookies.lang);
  let ukrActive = activeLang === FeLanguage.Ukr;
  let ukrSwitchStyle = classNames(styles.langSwitch, {
    [styles.inactive]: !ukrActive,
    [styles.active]: ukrActive,
  });

  let engActive = activeLang === FeLanguage.Eng;
  let engSwitchStyle = classNames(styles.langSwitch, {
    [styles.inactive]: !engActive,
    [styles.active]: engActive,
  });

  let sweActive = activeLang === FeLanguage.Swe;
  let sweSwitchStyle = classNames(styles.langSwitch, {
    [styles.inactive]: !sweActive,
    [styles.active]: sweActive,
  });
  return (
    <Col>
      <Row>
        <Link to={`${FeResource.Ukrainian}?referrer=${location.pathname}`} className={ukrSwitchStyle}>
          Українська
        </Link>
      </Row>
      <Row>
        <Link to={`${FeResource.English}?referrer=${location.pathname}`} className={engSwitchStyle}>
          English
        </Link>
      </Row>
      <Row>
        <Link to={`${FeResource.Swedish}?referrer=${location.pathname}`} className={sweSwitchStyle}>
          Svenska
        </Link>
      </Row>
    </Col>
  );
};
